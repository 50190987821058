import React, { useContext } from "react"

import Layout from "../components/wrappers/layout"
import SiteHeaderAlt from "../components/wrappers/site-header-alt"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { domain } from "../utils"
import Header from '../components/header';
import TabbedItem from '../components/wrappers/tabbed-item';
import TabbedList from '../components/wrappers/tabbed-list';
import { graphql } from 'gatsby';
import ServiceCard from "../components/service-card";
import { getSinglePage } from "../utils";
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider"

const Careers = props => {
  const { careersOverview, footers, headers, general } = props.data.strapi;
  const { generalBackgroundColorDarkMode, generalBackgroundColorLightMode , fontColorDarkMode, fontColorLightMode } = general; 
  const { copyright } = careersOverview;
  
  const language = props.pageContext.language
  const header = headers && headers.find((header)=>  header.language === language ); 
  const footer = footers && footers.find((footer)=>  footer.language === language ) || {}; 

  const { links, collage1, points, collage2, seo} = careersOverview;

  // getting  page first H1 to set as meta title and description if not set in strapi .
  const pageFirstHeading = links.header && (links.header.supheading + " " + links.header.heading ); 
  
  const seoTitle = seo && seo.metaData.metaTitle || pageFirstHeading|| "careers Overview";
  const seoDescription = seo && seo.metaData.metaDescription || pageFirstHeading||"careers Overview";
  const seoKeywords = seo && seo?.metaData?.keywords;
  const webpages  = careersOverview && careersOverview.webpages || {}; 
  const allPages = props.data?.allSitePage?.nodes; 
  const redirectTo = getSinglePage(webpages, allPages); 
  const state = useContext(GlobalStateContext);
  const darkMode = state.darkModePreference === 'moon'; 
  const imageBorderRadiusForServices = general.imageBorderRadiusForServices; 
  const sectionBackgroundColor = darkMode ? generalBackgroundColorDarkMode : generalBackgroundColorLightMode; 
  const websiteFontColor = darkMode ? fontColorDarkMode : fontColorLightMode; 
  if (copyright) {
    footer.copyright = copyright;
  }
  return (
    <Layout header={header} footer={footer} language={language} redirectTo={redirectTo}>
      <SEO
        websiteFontColor={websiteFontColor} 
        template="careers" 
        title={seoTitle} 
        description={seoDescription} 
        keywords={seoKeywords} 
        darkTheme={careersOverview.darkTheme}
        darkMode={darkMode}  
        websiteBackgroundColor={sectionBackgroundColor}
      />

      <SiteHeaderAlt header={header} redirectTo={redirectTo}>
        {links && (
          <section className="section section-small-margin" style={{backgroundColor: sectionBackgroundColor}}> 
            {links.header && (
              <Header heading={links.header.heading} supheading={links.header.supheading} />
            )}

            {links.links && links.links.length > 0 && (
              <div className="container">
                <div className="row">
                  <div className="offset-tw-1 col-tw-10 offset-lg-2 col-lg-7 rich-text">
                    <ul className="list list-border list-arrowed">
                      {links.links.map((link, index) => {
                        return (<li index={`list-${index}`}><a className="text-decoration-none" href={`/${language}/${link.path}`}>{link.label}</a></li>)
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </section>
        )}

        {collage1 && (
          <section className="section" style={{backgroundColor: sectionBackgroundColor}}>
            <div className="collage collage--short">
              <div className="container">
                <div className="row">
                  <div className="offset-md-1 col-md-3 collage-item">
                    {collage1[0] && (
                      <img 
                        src={domain+collage1[0].url} 
                        style={{borderRadius: imageBorderRadiusForServices}}
                        alt={collage1[0].alternativeText} 
                        loading="lazy"
                      />
                    )}
                  </div>
                  <div className="offset-md-1 col-md-6 collage-item">
                    {collage1[1] && (
                      <img 
                        src={domain+collage1[1].url} 
                        style={{borderRadius: imageBorderRadiusForServices}}
                        alt={collage1[1].alternativeText} 
                        loading="lazy"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}


        {points && (
          <section className="section tabbed" style={{backgroundColor: sectionBackgroundColor}}>
            {points.header && (
              <Header heading={points.header.heading} supheading={points.header.supheading} />
            )}

            {points.services && (
              <div className="container">
                <TabbedList>
                  {points.services.map((service, index) => (
                    <TabbedItem 
                      id={( index + 1 < 10 ? "0" : "")+(index+1)} 
                      group="services" heading={service?.heading} 
                      initiallyChecked={index === 0}
                      imageBorderRadiusForServices={imageBorderRadiusForServices}
                    >
                      <ServiceCard 
                        service={service} 
                        showHeading={false} 
                        language={language} 
                        imageBorderRadiusForServices={imageBorderRadiusForServices} 
                        websiteFontColor={websiteFontColor}
                      />
                    </TabbedItem>
                  ))}
                </TabbedList>
              </div>
            )}
          </section>
        )}

        {collage2 && (
          <section className="section zero-margin_bottom" style={{backgroundColor: sectionBackgroundColor}}>
            <div className="collage collage--short">
              <div className="container">
                <div className="row">
                  <div className="offset-md-1 col-md-6 collage-item">
                    {collage2[0] && (
                      <img 
                        src={domain+collage2[0].url} 
                        style={{borderRadius: imageBorderRadiusForServices}}
                        alt={collage2[0].alternativeText} 
                        loading="lazy"
                      />
                    )}
                  </div>
                  <div className="offset-md-1 col-md-3 collage-item">
                    {collage2[1] && (
                      <img 
                        src={domain+collage2[1].url} 
                        style={{borderRadius: imageBorderRadiusForServices}}
                        alt={collage2[1].alternativeText} 
                        loading="lazy"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </SiteHeaderAlt>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Careers($id: ID!, $language: String!) {
    allSitePage {
      nodes {
        path
      }
    }
    strapi {
      general {
        primaryBtn
        blogsOverviewHeadline
        imageBorderRadiusForServices
        greySectionLightModeBgColor
        greySectionDarkModeBgColor
        generalBackgroundColorDarkMode
        generalBackgroundColorLightMode
        fontColorDarkMode
        fontColorLightMode
      }
      headers(where: {language: $language}) {
        ...SiteHeader
      }
      footers(where: { language: $language }) {
        ...SiteFooter
      }
      careersOverview(id: $id) {
        copyright
        darkTheme
         webpages {
            about_us {
              language
            }
            contact {
              language
            }
            blogs_overview {
              language
            }
            cases_overview {
              language
            }
            case_study {
              slug
              language
            }
            careers_overview {
              language
            }	
            services_overview {
              language
            }
            blog {
              language
              slug
            }
            career {
              language
              slug
            }
            homepage {
              slug
              language
            }
            service_design {
              slug
              language
            }
            service_technical {
              slug
              language
            }
            service_casey_new {
              slug
              language
            }
            service_casey {
              slug
              language
            }
            plainpage {
              language
              slug
            }
          }
         seo {
          metaData {
            metaTitle
            metaDescription
            keywords
          }
        }
        links {
          header {
            supheading
            heading
          }
          links {
            label
            path
          }
        }
        collage1 {
          url
          alternativeText
          mime
          ext
        }
        points {
          header {
            supheading
            heading
          }
          services {
            heading
            media {
              media {
                url 
              }
            }
            body {
              richText
            }
            link {
              label
              path
            }
          }
        }
        collage2 {
          url
          alternativeText
          mime
          ext
        }
      }
    }
  }
`

export default Careers
